import React from 'react';
import bg1 from './assets/bg_1.png'
import bg2 from './assets/bg_2.png'
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img width="100%" src={bg1} alt="logo" />
      </header>
      <footer className="footer">
        <img width="100%" src={bg2} alt="logo" />
      </footer>

      <div className="text-1">186****9609的行程卡</div>
      <div className="text-2">更新于：2021-11-28 09:32:31</div>
      <div className="text-3">您于前14天内到达或途径
      <strong>江苏省南京市，上海市（注：*表示当前该城市存在中风险或高风险地区，并不表示用户实际到访过这些中高风险地区。）</strong></div>
    </div>
  );
}

export default App;
